.achievement {
    background-color: #ffffff;
    width: 70%;
    /* 幅 */
    height: 3.3vw;
    /* 高さ */
    position: relative;
    top: 50%;
    left: 64%;
    transform: translateX(-50%);
    /* padding-left: 20px; */
    /* 左側の余白 */
    /* padding-right: 20px; */
    /* 右側の余白 */
    display: flex;
    justify-content: left;
    /* 横方向の中央揃え */
    align-items: center;
    /* 縦方向の中央揃え */

}

.achievement p {
    color: #03b4a5;
    font-size: 2vw;
    text-align: left;
    font-weight: bold;
}


@media screen and (width<=400px) {
    .achievement {
        width: 86%;
        left:53%;
        padding-left: 4vw;
    }

    .achievement p {
        font-size: 4vw;
    }
}