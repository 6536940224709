.text-onebyone {
  position: relative;
  .text-split {
    font-size: 4rem;
    clip-path: inset(0 0 0);
    .text-each {
      display: inline-block;
      opacity: 0;
      transform: translateY(100%);
      &.is-active {
        transition: 1s;
        opacity: 1;
        transform: translateY(0%);
      }
    }
  }
  .for-accessibility {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
  }
}

.murasaki {
  color: #6F51A1;
  font-size: 3vw;
  
  
}

.murasaki p {
  font-size: 2vw;
  text-align: left;
  margin: 0 auto;
  max-width: 60%;
  padding-left: 10%;
}

.midori{
  color:#03b4a5;
  font-size: 3vw;
}

.midori p {
    font-size: 2vw;
    text-align: left;
    margin: 0 auto;
    max-width: 60%;
    padding-left: 10%;

}

@media screen and (width<=800px) {
  .midori{
    font-size: 8vw;
  }
  .midori p{
    font-size: 4.59vw;
    max-width: 100%;
    padding-left: 7vw;
  }
  .murasaki{
    font-size: 8vw;
  }
  .murasaki p{
    font-size: 4.73vw;
    max-width: 100%;
    padding: 2vw;
  }
  
}