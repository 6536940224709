.top-page {
    position: relative;
    max-height: 100%;
    height: 97vh;
}

.large-photo {
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
    object-fit: cover;
    transition: opacity 1s ease-in-out;
    opacity: 0;
}

.large-photo.fade-in {
    opacity: 1;
}

.large-photo.fade-out {
    opacity: 0;
}

.text-overlay {
    max-width: 100%;
    position: absolute;
    top: 1%;
    left: 8vw;
    width: 70%;
    color: #6F51A1;
    font-size: 7vw;
    text-align: left;
    z-index: 1;
}

@media screen and (width<= 800px) {

    .large-photo,
    .text-overlay {
        height: 90vh;
        width: 20%;
        width: auto;
        top: 20%;
        left: 4%;
        font-size: 13vw;
    }
}