.scroll-text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    font-size: 3vw;
    max-width: 100%;
    white-space: pre-wrap;
    padding-top: 2vw;
    padding-bottom: 2vw;
}

@media screen and (width<=800px) {
    .scroll-text {
        font-size: 4.5vh;
        padding-left: 2%;
        padding-right: 2%;
    }

}