.nav {
    position: relative;
    background-color: #6F51A1;
}

.nav ul {
    width: 90%;
    display: flex;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    justify-content: center; 
}

.nav a {
    text-decoration: none;
    color: #fff;
    padding: 7px 7px; 
    font-size: 1vw;
    display: flex; 
    align-items: center; 
    display: flex;
    align-items: center;
}

@media screen and (width<=400px) {
    .nav {
        background-color: #6F51A1;
        height: auto;
    }

    .nav ul {
        flex-direction: column;
        padding: 0;
    }

    .nav li {
        /* border-bottom: solid 1px #fff; */
    }
}
