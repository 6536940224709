.member {
    display: flex;
    justify-content: center;
    /*中央寄せ */
    text-align: center;
    padding-left: 0%;
    flex-wrap: wrap;
}

.member li {
    position: relative;
    /* 相対位置指定 */
    width: calc(20% - 0px);
    padding: 0.9vw;
    color: #fff;
    margin-left: 0;
}

.member li img {
    max-width: 100%;
    height: auto;
    /* border: solid 1px #fff; */
    position: relative;
    z-index: 1;
}

.member li .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 50%;
    width: 50px;
    /* 任意のサイズ */
    height: 50px;
    /* 任意のサイズ */
    z-index: 2;
}

.member li p {
    position: relative;
    height: 2vh;
    width: 100%;
    top: 0.5vh;
    color: #6F51A1;
    font-size: 1.2vw;
    font-weight: bold;
    text-align: center;
    padding: 0%;
    top: -1.5vh
}

@media screen and (max-width: 1200px) {
    .member li {
        width: calc(45% - 2px);
        padding: 1vw;
    }

    .member li p {
        font-size: 2vw;
        padding: 0%;
        top: -0.5vh
    }
}

@media screen and (max-width: 800px) {
    .member li {
        width: calc(50% - 20px);
    }

    .member li p {
        font-size: 3vw;
        top: 0vh;
        top: -1.3vh;
    }
}