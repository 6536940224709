.introduce {
    display: flex;
    align-items: center;
    padding: 20px;
}

.introduce__image {
    padding-left: 10vw;
    flex: 0 0 34%;
    text-align: center;
        max-width: 100%;
    
}

.introduce__image img {
    height: auto;
    width: 80%;
}

.introduce__description {
    text-align: left;
    padding-right: calc(5% + 5vw);
    padding-left: calc(5% - 5vw);

}

.introduce__description h2 {
    padding-right: 5%;
    padding-bottom: 0;
    margin-top: 0;
    font-size: 2.5vw;
}

.introduce__description p {
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.3vw;
    color: rgb(89, 88, 86);
}

@media screen and (width<=1200px) {
    .introduce {
        flex-direction: column;
        padding-top: 20px;
    }

    .introduce__image {
        width: 100%;
        order: 0;
        flex: 0 0 100%;
        text-align: center;
        padding-left: 0;
    }

    .introduce__description {
        order: 1;
        padding-right: 0;
        padding-left: 0;
    }

    .introduce__description h2 {
        font-size: 5vw;
        padding-top: 2vw;
        text-align: center;
    }

    .introduce__description p {
        font-size: 3.2vw;
        padding-left:5% ;
        padding-right: 5%;
    }
}