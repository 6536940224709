.news {
    display: flex;
    align-items: center;
    padding: 20px;
    white-space: pre-line;
}


.news__image {
    padding-right: 4vw;
    max-width: 100%;
    /* 左側に配置するためにpadding-rightに変更 */
    flex: 0 0 35%;
    text-align: center;
    order: 1;
}


.news__image img {
    width: 80%;
    height: auto;
}


.news__description {
    padding-left: 10%;
    order: 0;
}


.news__description h2 {
    text-align: center;
    margin-top: 0;
    font-size: 1.79vw;
}


.news__description p {
    padding-right: 5%;
    text-align: left;
    margin-bottom: 0%;
    font-size: 1.4vw;
    color: rgb(89, 88, 86); 
}


@media screen and (width<=800px) {
    .news {
        display: block;
        padding: 15px;
    }

    .news__image {
        width: 100%;
        margin: 0 auto;
        padding-right: 0;
   
    }

    .news__description {
        padding-left: 0;
        order: 1;
        /* padding-left: 5%;
        padding-right: 5%; */
    }

    .news__description h2 {
        padding-top: 1vw;
        padding-bottom: 0;
        font-size: 4.6vw;
        text-align: center;
    }

    .news__description p {
        padding-right: 0;
        font-size: 3.3vw;
        padding-bottom: 5%;
        text-align: left;
    }
}